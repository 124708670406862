import React, { useState, useCallback } from 'react';
import Crop from '../Crop';
import PhotosService from 'services/photos';
import { useToasts } from 'react-toast-notifications';
import {
  StyledPaper,
  StyledFile,
  SuccessButton,
  SuccessButtons,
  SuccessContainer,
  SuccessInfo,
  SuccessTick,
} from './Adding.atoms';

export const addInvoice = (formData, file, handleSuccess, addToast, setLoading) => {
  const data = new FormData();
  data.append('file', file, file.name);
  data.append('password', formData.password);
  data.append('description', formData.description);

  setLoading(true);

  PhotosService.save(data)
    .then(() => {
      handleSuccess();
    })
    .catch((err) => {
      addToast('Wystapił błąd podczas przetwarzania pliku.', { appearance: 'error' });
    });
};

function Adding() {
  const { addToast } = useToasts();

  const [image, setImage] = useState();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSelectFile = useCallback(
    (files) => {
      if (files && files.length > 0) {
        const file = files[0];
        const reader = new FileReader();

        if (file.type.search(/^image\//i) !== -1) {
          reader.addEventListener('load', () => {
            setImage(reader.result);
          });
        } else {
          return addToast('Wybrano niewspierany format pliku.', { appearance: 'error' });
        }

        reader.readAsDataURL(file);
      }
    },
    [addToast, setImage]
  );

  const handleSuccess = () => {
    setSuccess(true);
    setLoading(false);
  };

  const onSubmit = (file, data) => {
    if (!loading) {
      addInvoice(data, file, handleSuccess, addToast, setLoading);
    }
  };

  return (
    <StyledPaper>
      <h2>Adding photo</h2>

      {!success && !image && (
        <StyledFile>
          <label htmlFor="file-upload">
            <span>Select file</span>
          </label>

          <input id="file-upload" type="file" accept=".jpg,.jpeg,.png" onChange={onSelectFile} />
        </StyledFile>
      )}

      {!success && image && (
        <>
          <Crop image={image} handleCrop={onSubmit} />
        </>
      )}

      {success && (
        <SuccessContainer>
          <SuccessTick>
            <span className="icon-checkmark" />
          </SuccessTick>

          <SuccessInfo>Photo has been added</SuccessInfo>

          <SuccessButtons>
            <SuccessButton to="/photos">Back to list</SuccessButton>
            <SuccessButton to="/add">Add one more</SuccessButton>
          </SuccessButtons>
        </SuccessContainer>
      )}
    </StyledPaper>
  );
}

export default Adding;
