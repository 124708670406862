import { asService, Service } from './index';

class AccountService extends Service {
  login(data) {
    return this.client.post('/account/login', data);
  }

  checkToken() {
    return this.client.get('/account/checkToken');
  }
}

export default asService(new AccountService());
