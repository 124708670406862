import { useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import accountService from 'services/account';
import { setUser, setTokenAsChecked } from 'models/account/actions';

const CheckToken = () => {
  const dispatch = useDispatch();
  const loggedByForm = useSelector((state) => state.account.loggedByForm);

  useEffect(() => {
    accountService
      .checkToken()
      .then((response) => {
        batch(() => {
          dispatch(setUser(response));
          dispatch(setTokenAsChecked());
        });
      })
      .catch(() => {
        dispatch(setTokenAsChecked());
      });
  }, [dispatch, loggedByForm]);

  return null;
};

export default CheckToken;
