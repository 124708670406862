import React from 'react';
import { useLocation } from 'react-router-dom';
import Adding from '../Adding';

const AddingPage = () => {
  const { key } = useLocation();

  return <Adding key={key} />;
};

export default AddingPage;
