import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from 'components/account/Login';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLogin = useSelector((state) => !!state.account.user);
  const tokenChecked = useSelector((state) => state.account.tokenChecked);

  if (!tokenChecked) {
    return null;
  }

  return <Route {...rest} render={(props) => (isLogin ? <Component {...props} /> : <Login />)} />;
};

export default PrivateRoute;
