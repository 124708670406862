import React, { useEffect, useState, useCallback } from 'react';
import { Table, TableContainer, TableBody } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import { useLoading } from 'components/Loading';
import { useLocation } from 'react-router-dom';
import PhotosService from 'services/photos';
import EnhancedTableToolbar from 'components/table/Toolbar';
import EnhancedTableHead from 'components/table/Head';
import useTable from 'components/table/useTable';
import styled from 'styled-components';
import Row from './Row';
import PasswordContext from './Context';

const Header = styled.h2`
  font-size: 1rem;
  padding: 5px;
  margin: 1rem 0;
  padding-left: 15px;
`;

const PasswordInput = styled.input`
  display: block;
  width: calc(100% - 65px);
  box-sizing: border-box;
  border: 2px solid #da363f;
  background: none;
  padding: 10px 12px;
  border-radius: 100px;
  margin-bottom: 10px;
  outline: none;
`;

const headCells = [
  { id: 'author', align: 'left', sortable: true, label: 'Autor' },
  { id: 'created_at', align: 'left', sortable: true, label: 'Data dodania' },
  { id: 'download', align: 'right', sortable: false, label: 'Pobierz' },
];

const List = () => {
  const { addToast } = useToasts();

  const [items, setItems] = useState([]);
  const [pass, setPass] = useState('');
  const [howManyItems, setHowManyItems] = useState(0);
  const [apiIterator, setApiIterator] = useState(0);
  const [loading, setLoading] = useLoading();
  const { key } = useLocation();

  const {
    rowsPerPage,
    page,
    order,
    orderBy,
    selected,
    onChangeSelected,
    onRequestSort,
    onChangePage,
    onChangeRowsPerPage,
    onSelectItem,
  } = useTable('created_at');

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = items.map((item) => item.id);

      return onChangeSelected(newSelecteds);
    }

    onChangeSelected([]);
  };

  const isSelected = (value) => selected.indexOf(value) !== -1;

  useEffect(() => {
    setLoading(true);

    PhotosService.list(new Date(), order, orderBy, page, rowsPerPage)
      .then((data) => {
        setItems(data.data);
        setHowManyItems(data.pagination.count);
      })
      .catch(() => {
        addToast('Coś poszło nie tak.', { appearance: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [rowsPerPage, page, orderBy, order, setLoading, addToast, key, apiIterator]);

  const remove = useCallback(() => {
    setLoading(true);

    PhotosService.remove(selected)
      .then(() => {
        addToast('Dokumenty zostały usunięte', { appearance: 'success' });

        onChangeSelected([]);

        setApiIterator(apiIterator + 1);
      })
      .catch(() => {
        addToast('Coś poszło nie tak.', { appearance: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, setLoading, selected, apiIterator, setApiIterator, onChangeSelected]);

  return (
    <>
      <Header>Photos</Header>

      <PasswordInput
        placeholder="Set global password"
        type="password"
        onChange={(e) => setPass(e.target.value)}
        value={pass}
      />

      <PasswordContext.Provider value={pass}>
        {items.map((row, index) => (
          <Row key={`${row.name}${index}`} row={row} handleClick={onSelectItem} isItemSelected={isSelected(row.id)} />
        ))}
      </PasswordContext.Provider>
    </>
  );
};

export default List;

/*
        <EnhancedTableToolbar handleRemove={remove} numSelected={selected.length} title="Zdjęcia" />



        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={onSelectAllClick}
              onRequestSort={onRequestSort}
              rowCount={items.length}
              headCells={headCells}
            />
            <TableBody>

            </TableBody>
          </Table>
        </TableContainer>


          <StyledTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={howManyItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          disabled={loading}
        />

 */
