import React from 'react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Delete as DeleteIcon, Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';

function SimpleDialog(props) {
  const { onClose, open } = props;

  return (
    <Dialog
      onClose={() => {
        onClose(false);
      }}
      open={open}
    >
      <DialogTitle>Czy na pewno chcesz potwierdzić akcję?</DialogTitle>
      <List>
        <ListItem button onClick={() => onClose(true)}>
          <ListItemAvatar>
            <Avatar>
              <CheckIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Potwierdź" />
        </ListItem>

        <ListItem button onClick={() => onClose(false)}>
          <ListItemAvatar>
            <Avatar>
              <CloseIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Anuluj" />
        </ListItem>
      </List>
    </Dialog>
  );
}

const Prompt = ({ handleRemove }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);

    if (value) {
      handleRemove();
    }
  };

  return (
    <>
      <Tooltip title="Usuń" onClick={handleClickOpen}>
        <IconButton>
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <SimpleDialog open={open} onClose={handleClose} />
    </>
  );
};

export default Prompt;
