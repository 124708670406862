import axios from 'axios';
import store from 'core/configuredStore';
import { logout } from 'models/account/actions';

class Services {
  list = [];
  client = null;
  login = false;

  constructor(store) {
    this.store = store;
    this.client = this.createNewInstance();
  }

  setToken(token) {
    localStorage.setItem('token', token);

    this.login = true;

    this.refresh();
  }

  logout() {
    localStorage.removeItem('token');

    this.refresh();

    this.store.dispatch(logout());
  }

  createNewInstance() {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 60000,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    const that = this;

    instance.interceptors.response.use(
      function (response) {
        that.login = true;

        return response.data;
      },
      function (error) {
        if (error && error.response && error.response.status === 401) {
          if (that.login) {
            that.logout();
          }
        }

        return Promise.reject(error);
      }
    );

    return instance;
  }

  add(service) {
    this.list.push(service);

    service.setClient(this.client);
  }

  refresh() {
    this.client = this.createNewInstance();

    this.list.forEach((service) => {
      service.setClient(this.client);
    });
  }
}

export class Service {
  setClient(client) {
    this.client = client;
  }
}

const serviceContainer = new Services(store);

export const asService = (service) => {
  serviceContainer.add(service);

  return service;
};

export default serviceContainer;
