import React, { createContext, useCallback, useMemo, useState, useContext, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import StyledLoading from './Loading.styled';

const LoadingContext = createContext({});

export const useLoading = () => {
  const uuid = useMemo(() => uuidv4(), []);
  const { loadingList, handleSetLoading } = useContext(LoadingContext);

  useEffect(() => {
    return () => {
      // destructor
      handleSetLoading(uuid, false);
    };
  }, [handleSetLoading, uuid]);

  const setLoading = useCallback(
    (isLoading) => {
      handleSetLoading(uuid, isLoading);
    },
    [handleSetLoading, uuid]
  );

  const value = useMemo(() => {
    return loadingList.indexOf(uuid) >= 0;
  }, [loadingList, uuid]);

  return [value, setLoading];
};

export const Provider = ({ children }) => {
  const listRef = useRef([]);
  const [, setLength] = useState(0);

  const handleSetLoading = useCallback((uuid, isLoading = true) => {
    const loadingList = listRef.current;

    if (isLoading) {
      if (loadingList.indexOf(uuid) < 0) {
        const newList = [...loadingList, uuid];

        listRef.current = newList;
        setLength(newList.length);
      }
    } else {
      if (loadingList.indexOf(uuid) >= 0) {
        const newList = [...loadingList].filter((_uuid) => _uuid !== uuid);

        listRef.current = newList;
        setLength(newList.length);
      }
    }
  }, []);

  const contextValue = {
    loadingList: listRef.current,
    handleSetLoading,
  };

  return <LoadingContext.Provider value={contextValue}>{children}</LoadingContext.Provider>;
};

export const Progress = () => {
  return (
    <LoadingContext.Consumer>
      {({ loadingList }) => {
        if (loadingList.length > 0) {
          return (
            <StyledLoading>
              <span>
                <span />
              </span>
            </StyledLoading>
          );
        }

        return null;
      }}
    </LoadingContext.Consumer>
  );
};
