import React, { useState } from 'react';
import styled from 'styled-components';
import Download from './Download';

const Container = styled.div`
  margin-top: 10px;
  background: #1a1a1a;
  padding: 10px;
  border-radius: 6px;
`;

const Details = ({ row }) => {
  return (
    <Container>
      <Download data={row} />
    </Container>
  );
};

export default Details;
