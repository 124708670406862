import { asService, Service } from './index';
import { formatISO } from 'date-fns';

const TIMEOUT = 30000;

class DocumentsService extends Service {
  analyze(data) {
    return this.client.post('/documents/analyze', data, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
      timeout: TIMEOUT,
    });
  }

  save(data) {
    return this.client.post('/documents', data, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
      timeout: TIMEOUT,
    });
  }

  list(date, order, orderBy, page, rowsPerPage) {
    return this.client.get('/documents', {
      params: {
        date: formatISO(date),
        order,
        orderBy,
        page,
        rowsPerPage,
      },
    });
  }

  remove(ids) {
    return this.client.delete(`/documents`, {
      params: {
        id: ids,
      },
    });
  }

  show(id, data) {
    return fetch(`${process.env.REACT_APP_API_URL}documents/show?id=${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    });
  }
}

export default asService(new DocumentsService());
