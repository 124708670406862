import React, { useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import Details from './Details';
import { useSelector } from 'react-redux';

const Container = styled.div`
  position: relative;

  border-bottom: 1px solid #1a1a1a;
  padding: 10px;

  margin-right: 60px;
`;

const Icon = styled.div`
  position: absolute;
  top: 10px;
  left: 15px;
  width: 42px;
  height: 42px;
  background: rgb(202, 49, 132);
  background: linear-gradient(313deg, rgba(202, 49, 132, 1) 0%, rgba(218, 54, 61, 1) 62%, rgba(153, 44, 130, 1) 100%);
  border-radius: 100px;
  line-height: 42px;
  text-align: center;
`;

const Content = styled.div`
  margin-left: 60px;
`;

const User = styled.div`
  font-size: 13px;
  font-weight: bold;
`;

const DateItem = styled.div`
  font-size: 11px;
  color: #696969;
  margin-bottom: 10px;
  margin-top: 2px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 11px;
  color: #949494;
`;

const Arrow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 60px;

  &::before {
    content: '';
    display: block;

    width: 10px;
    height: 10px;

    border-left: 2px solid #6d6d6d;
    border-bottom: 2px solid #6d6d6d;

    position: absolute;
    top: 10px;
    right: 10px;
    transform: rotate(${(props) => (props.open ? '135' : '-45')}deg);
    transform-origin: center center;
  }
`;

const Stats = styled.div`
  display: flex;
  font-size: 11px;
  color: #696969;
  margin-top: 10px;
  font-weight: bold;
`;

const Stat = styled.div`
  margin-right: 10px;

  em {
    font-style: normal;
    font-weight: bold;
    color: #b7b7b7;
    margin-left: 3px;
  }
`;

const Row = ({ row, isItemSelected, handleClick }) => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.account.user);

  return (
    <Container>
      <Icon>
        <span className="icon-image" />
      </Icon>

      <Arrow
        open={open}
        onClick={() => {
          setOpen(!open);
        }}
      />

      <Content>
        <User>{row.user_name}</User>
        <DateItem>
          {format(new Date(row.created_at), 'dd.MM.yy HH:mm')}
          {row.owner === user.id ? ` (#${row.id})` : ''}
        </DateItem>

        {row.description && <Description>{row.description}</Description>}

        <Stats>
          <Stat>
            <span className="icon-eye" />
            <em>{row.seen_counter}</em>
          </Stat>
          <Stat>
            <span className="icon-paragraph-left" />
            <em>{row.comments_counter}</em>
          </Stat>
        </Stats>
      </Content>

      {open && <Details row={row} />}
    </Container>
  );
};

export default Row;
