import React, { useState, useCallback, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  ActionsContainer,
  ContentContainer,
  ImageContainer,
  Rotate,
  Area,
  AreaContainer,
  Save,
  SecretContainer,
  SecretInput,
  Left,
  CropWrapper,
} from './Crop.styled';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from 'components/elements/atoms';
import { yupResolver } from '@hookform/resolvers/yup';

const MAX_WIDTH = 800;

const schema = yup.object().shape({
  password: yup.string().required().min(6),
});

const Crop = ({ image: upImg, handleCrop }) => {
  const [imgRef, setImgRef] = useState();
  const [crop, setCrop] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [done, setDone] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const createCropPreview = async (image, crop) => {
    console.log('making preview...');

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const scale = image.naturalWidth / image.width;

    console.log(image.naturalWidth, image.width, scale);

    const sx = crop.x * scale;
    const sy = crop.y * scale;
    const sw = crop.width * scale;
    const sh = crop.height * scale;

    const newWidth = Math.min(MAX_WIDTH, sw);

    const newScale = sw / newWidth;
    const newHeight = sh / newScale;

    canvas.width = newWidth;
    canvas.height = newHeight;

    ctx.drawImage(image, sx, sy, sw, sh, 0, 0, newWidth, newHeight);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        blob.name = Date.now();

        setPreviewUrl(blob);

        resolve();
      }, 'image/jpeg');
    });
  };

  const makeClientCrop = async (crop) => {
    if (imgRef && crop.width && crop.height) {
      await createCropPreview(imgRef, crop);
    }
  };

  const onLoad = useCallback(
    (img) => {
      setImgRef(img);

      const defaultCrop = {
        x: 0,
        y: 0,
        width: img.width,
        height: img.height,
      };

      setCrop(defaultCrop);

      return false;
    },
    [setImgRef, setCrop]
  );

  useEffect(() => {
    makeClientCrop(crop);
  }, [imgRef]);

  const rotate = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const image = new Image();

    image.onload = function () {
      canvas.width = image.height;
      canvas.height = image.width;

      ctx.drawImage(image, 0, 0);
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(Math.PI / 2);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);
      ctx.rotate(-Math.PI / 2);
      ctx.translate(-canvas.width / 2, -canvas.height / 2);

      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }

        blob.name = Date.now();

        setPreviewUrl(blob);
      }, 'image/jpeg');
    };

    image.src = URL.createObjectURL(previewUrl);
  };

  const onSubmit = (data) => {
    handleCrop(previewUrl, data);
  };

  return (
    <>
      <ContentContainer>
        {!done && (
          <CropWrapper>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={setCrop}
              onComplete={makeClientCrop}
              keepSelection={true}
            />
          </CropWrapper>
        )}

        {done && (
          <ImageContainer>
            <img key={previewUrl.name} src={URL.createObjectURL(previewUrl)} alt="Preview" />

            <Rotate
              onClick={() => {
                rotate();
              }}
            ></Rotate>
          </ImageContainer>
        )}
      </ContentContainer>

      {!done && previewUrl && (
        <ActionsContainer>
          <Button
            onClick={() => {
              setDone(true);
            }}
          >
            Crop
          </Button>
        </ActionsContainer>
      )}

      {done && (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <AreaContainer>
            <Left>
              <Area placeholder="Description (optional)" name="description" ref={register} />

              <SecretContainer>
                <SecretInput placeholder="Secret" type="password" name="password" ref={register} />
              </SecretContainer>
            </Left>

            <Save type="submit">
              <span className="icon-compass" />
            </Save>
          </AreaContainer>
        </form>
      )}
    </>
  );
};

export default Crop;
