import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Container, Logo, ButtonContainer, InputContainer, Input, Alert } from './Login.styled';
import { Button } from 'components/elements/atoms';
import serviceContainer from 'services';
import accountService from 'services/account';
import { useDispatch } from 'react-redux';
import { setLoggedByForm } from 'models/account/actions';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  login: yup.string().required(),
  password: yup.string().required(),
});

const Login = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    accountService
      .login(data)
      .then((response) => {
        serviceContainer.setToken(response.token);

        dispatch(setLoggedByForm());
      })
      .catch((e) => {
        setError('_form', {
          type: 'error',
          message: 'login or password is invalid',
        });
      });
  };

  const error = Object.values(errors)[0];

  return (
    <Container>
      <Logo>
        <span>
          w<strong>Lawce</strong>
        </span>
      </Logo>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ButtonContainer>
          {error && <Alert>{error.message}</Alert>}

          {step === 0 && (
            <Button
              onClick={() => {
                setStep(1);
              }}
            >
              Start journey
            </Button>
          )}

          {step === 1 && (
            <>
              <InputContainer>
                <Input placeholder="Login" name="login" ref={register} />
              </InputContainer>

              <InputContainer>
                <Input placeholder="Password" name="password" type="password" ref={register} />
              </InputContainer>

              <Button type="submit" inverted>
                Log in
              </Button>
            </>
          )}
        </ButtonContainer>
      </form>
    </Container>
  );
};

export default Login;
