import React, { useState } from 'react';
import styled from 'styled-components';
import Comments from '../Comments';
import SeenBy from '../SeenBy';

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #313131;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  color: #676767;
  margin: 10px 0;
`;

const Tab = styled.div`
  border-bottom: 2px solid ${(props) => (props.selected ? '#d93642' : 'transparent')};
  padding: 0 10px;
  position: relative;
  top: 1px;
  padding-bottom: 3px;
`;

const Activities = ({ data }) => {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs>
        <Tab
          selected={tab === 0}
          onClick={() => {
            setTab(0);
          }}
        >
          Comments
        </Tab>
        <Tab
          selected={tab === 1}
          onClick={() => {
            setTab(1);
          }}
        >
          Seen by
        </Tab>
      </Tabs>

      {tab === 0 && <Comments data={data} />}
      {tab === 1 && <SeenBy data={data} />}
    </>
  );
};

export default Activities;
