import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import PrivateRoute from 'components/PrivateRoute';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import store from './core/configuredStore';
import CheckToken from './components/account/CheckToken';
import AddingPage from './components/documents/AddingPage';
import List from './components/documents/List';
import Menu from './components/Menu';
import Error404 from './components/Error404';
import MyCustomToast from './components/Toast';
import { Provider as LoadingProvider, Progress } from './components/Loading';
import theme, { GlobalStyle } from './core/theme';

export default function App() {
  return (
    <Provider store={store}>
      <LoadingProvider>
        <ThemeProvider theme={theme}>
          <ToastProvider components={{ Toast: MyCustomToast }} autoDismiss={true}>
            <Router>
              <GlobalStyle />

              <Menu />

              <Switch>
                <Redirect exact from="/" to="/photos" />

                <PrivateRoute exact path="/photos" component={List} />
                <PrivateRoute exact path="/add" component={AddingPage} />

                <Route>
                  <Error404 />
                </Route>
              </Switch>

              <CheckToken />
            </Router>

            <Progress />
          </ToastProvider>
        </ThemeProvider>
      </LoadingProvider>
    </Provider>
  );
}
