import React from 'react';
import { Snackbar } from '@material-ui/core';

const MyCustomToast = ({ appearance = 'success', children }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={true}
    message={children}
  />
);

export default MyCustomToast;
