import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgb(202, 49, 132);
  background: linear-gradient(313deg, rgba(202, 49, 132, 1) 0%, rgba(218, 54, 61, 1) 62%, rgba(153, 44, 130, 1) 100%);
  overflow: hidden;
`;

export const Logo = styled.div`
  margin: 3rem 0;

  span {
    background: ${(props) => props.theme.black};
    text-transform: uppercase;
    padding: 1rem 2rem;
    border-radius: 0 100px 100px 0;
    font-size: 1.5rem;
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.black};
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const InputContainer = styled.div`
  flex: none;
  width: 100%;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 1rem;
  border: none;
  margin-bottom: 1rem;
  border: none;
  background: none;
  border: 2px solid white;
  border-radius: 100px;
  box-sizing: border-box;
  color: white;
  outline: none;
`;

export const Alert = styled.div`
  position: absolute;
  top: -22px;
  left: 0;
  font-size: 0.75rem;
  text-align: center;
  right: 0;
  color: #ff7d8e;
  background: rgba(0, 0, 0, 0.3);
  padding: 4px;
`;

export const Dog = styled.div`
  position: absolute;
  top: -56px;
  left: 10px;
  width: 100px;
  height: 100px;
  background: url('/favicon.png') 0 0 no-repeat;
  background-size: 60px;
`;
