const errors = {
  any: 'This field is incorrect',
  incorrect_code: 'Incorrect code',
  length: 'incorrect length',
  max: 'Too long value',
  min: 'Too short value',
  not_unique: 'Value should be unique',
  required: 'This field is required',
};

/**
 * This function requires type as string or object with defined type property.
 *
 * @param type
 * @returns {string|*}
 */
const getError = (type) => {
  let key;

  if (typeof type === 'string') {
    key = type;
  } else {
    key = type.type;
  }

  if (Object.hasOwnProperty.call(errors, key)) {
    return errors[key];
  }

  return errors.any;
};

export default getError;
