import { asService, Service } from './index';

class CommentsService extends Service {
  add(id, data) {
    return this.client.post(`/comments/${id}`, data);
  }

  list(id) {
    return this.client.get(`/comments/${id}`);
  }
}

export default asService(new CommentsService());
