import React, { useCallback } from 'react';
import { Button, StyledTextarea, TextareaWrapper } from './Comments.styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import getError from '../../../utils/getError';
import { Error } from '../List/Download';
import CommentsService from 'services/comments';

const schema = yup.object().shape({
  comment: yup.string().required().min(1),
});

const AddComment = ({ data, handleRefresh }) => {
  const { register, handleSubmit, errors, setError, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback((formData) => {
    CommentsService.add(data.id, formData)
      .then(() => {
        reset();
        handleRefresh(Date.now());
      })
      .catch((e) => {
        console.log(e);

        setError('comment', 'incorrect_code');
      });
  }, []);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <TextareaWrapper>
        <StyledTextarea placeholder="Write comment..." spellCheck="false" ref={register} name="comment" />
        <Button type="submit">
          <span className="icon-compass" />
        </Button>
      </TextareaWrapper>

      {errors.comment && <Error>{getError(errors.comment)}</Error>}
    </form>
  );
};

export default AddComment;
