import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;

  width: 60px;
  height: 100%;

  background: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
`;

export const Avatar = styled.div`
  background: rgb(202, 49, 132);
  background: linear-gradient(313deg, rgba(202, 49, 132, 1) 0%, rgba(218, 54, 61, 1) 62%, rgba(153, 44, 130, 1) 100%);

  width: 42px;
  height: 42px;
  border-radius: 100px;
  margin: auto;
  text-align: center;
  line-height: 42px;

  font-size: 30px;
  color: white;
  font-weight: bold;
  margin: 20px auto;
`;

export const Button = styled.button`
  display: block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border: none;
  border-left: 4px solid ${(props) => (props.selected ? '#d93642' : 'transparent')};
  background: none;
  color: ${(props) => (props.selected ? 'white' : '#585858')};

  font-size: 1.25rem;
  outline: none;

  &:active {
    border-color: #2b2b2b;
  }
`;

export const Bottom = styled.div`
  padding: 20px 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
`;
