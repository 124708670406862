import { SET_USER, SET_LOGGED_BY_FORM, SET_TOKEN_AS_CHECKED, LOGOUT } from './actions';

const initialState = {
  loggedByForm: false,
  tokenChecked: false,
  user: null,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case SET_LOGGED_BY_FORM:
      return {
        ...state,
        loggedByForm: true,
      };

    case SET_TOKEN_AS_CHECKED:
      return {
        ...state,
        tokenChecked: true,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default accountReducer;
