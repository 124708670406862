import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const StyledTextarea = styled(TextareaAutosize)`
  color: white;
  background: none;
  border: none;
  margin: 9px 50px 7px 18px;
  width: calc(100% - 80px);
  font-size: 12px;
  outline: none;
  resize: none;
`;

export const TextareaWrapper = styled.div`
  border: 2px solid white;
  border-radius: 25px;
  position: relative;
`;

export const Button = styled.button`
  width: 30px;
  height: 30px;
  background: rgb(202, 49, 132);
  background: linear-gradient(313deg, rgba(202, 49, 132, 1) 0%, rgba(218, 54, 61, 1) 62%, rgba(153, 44, 130, 1) 100%);
  border-radius: 100px;
  position: absolute;
  right: 4px;
  bottom: 4px;
  tex-align: center;
  line-height: 30px;
  color: white;
  border: none;
  outline: none;
`;

export const CommentWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

export const CommentAvatar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  border: 2px solid #585858;
  text-align: center;
  line-height: 24px;
  color: #585858;
`;

export const CommentContainer = styled.div`
  margin-left: 40px;
`;

export const CommentAuthor = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const CommentDate = styled.div`
  font-size: 11px;
  color: #696969;
  margin-bottom: 10px;
  margin-top: 2px;
  font-weight: bold;
`;

export const CommentContent = styled.div`
  font-size: 11px;
  color: #949494;
`;
