import styled, { css } from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Prompt from './Prompt';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const StyledHeaderTypography = styled(Typography)`
  flex: 1 1 100%;
`;

const StyledToolbar = styled(({ highlight, ...rest }) => <Toolbar {...rest} />)`
  ${(props) =>
    props.highlight &&
    css`
      color: rgb(220, 0, 78);
      background-color: rgb(250, 224, 233);
    `}
`;

const EnhancedToolbar = (props) => {
  const { numSelected, title, handleRemove } = props;

  return (
    <StyledToolbar highlight={numSelected > 0}>
      {numSelected > 0 ? (
        <StyledHeaderTypography variant="subtitle1" component="div">
          Zaznaczono: {numSelected}
        </StyledHeaderTypography>
      ) : (
        <StyledHeaderTypography variant="h6" component="div">
          {title}
        </StyledHeaderTypography>
      )}

      {numSelected > 0 && <Prompt handleRemove={handleRemove} />}
    </StyledToolbar>
  );
};

export default EnhancedToolbar;
