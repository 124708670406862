import styled from 'styled-components';

export const Button = styled.button`
  background: ${(props) => (props.inverted ? 'white' : 'none')};
  color: ${(props) => (props.inverted ? 'rgba(218,54,61,1)' : 'white')};
  border: 2px solid white;
  padding: 1rem 3rem;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;

  &:active {
    box-shadow: 0 0 26px #d9363e;
    transform: scale(0.95);
  }
`;
