import { createGlobalStyle } from 'styled-components';

const mainTheme = {
  black: '#000',
  pink: '#b13a54',
};

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?2ma96s');
  src:  url('fonts/icomoon.eot?2ma96s#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?2ma96s') format('truetype'),
    url('fonts/icomoon.woff?2ma96s') format('woff'),
    url('fonts/icomoon.svg?2ma96s#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-image:before {
  content: "\\e90d";
}
.icon-images:before {
  content: "\\e90e";
}
.icon-compass:before {
  content: "\\e949";
}
.icon-switch:before {
  content: "\\e9b6";
}
.icon-download2:before {
  content: "\\e9c5";
}
.icon-upload2:before {
  content: "\\e9c6";
}
.icon-eye:before {
  content: "\\e9ce";
}
.icon-checkmark:before {
  content: "\\ea10";
}
.icon-paragraph-left:before {
  content: "\\ea77";
}




  body {
    background: black;
    padding: 0;
    margin: 0;
  }
  
  body, textarea, input {
      color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
  }
`;

export default mainTheme;
