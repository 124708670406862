import { combineReducers } from 'redux';

import account from 'models/account/reducer';

const combinedReducers = combineReducers({
  account,
});

const rootReducer = (state, action) => {
  if (action.type === '@@LOGOUT') {
    state = undefined;
  }

  return combinedReducers(state, action);
};

export default rootReducer;
