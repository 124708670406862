import React, { useEffect, useState } from 'react';
import {
  CommentAuthor,
  CommentAvatar,
  CommentContainer,
  CommentContent,
  CommentDate,
  CommentWrapper,
} from './Comments.styled';
import CommentsService from 'services/comments';
import { useToasts } from 'react-toast-notifications';
import { format } from 'date-fns';
import AddComment from './AddComment';

export const Comment = ({ asSeen, data }) => {
  return (
    <CommentWrapper>
      <CommentAvatar>
        <span className={`icon-${asSeen ? 'eye' : 'compass'}`} />
      </CommentAvatar>
      <CommentContainer>
        <CommentAuthor>{data.user_name}</CommentAuthor>
        <CommentDate>{format(new Date(data.created_at), 'dd.MM.yy HH:mm')}</CommentDate>
        {!asSeen && <CommentContent>{data.content}</CommentContent>}
      </CommentContainer>
    </CommentWrapper>
  );
};

const Comments = ({ data }) => {
  const [comments, setComments] = useState([]);
  const { addToast } = useToasts();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    CommentsService.list(data.id)
      .then((response) => {
        setComments(response.data);
      })
      .catch((err) => {
        addToast('Error occurs during fetching.', { appearance: 'error' });
      });
  }, [time]);

  return (
    <>
      {comments.map((comment) => (
        <Comment data={comment} key={comment.id} />
      ))}
      <AddComment data={data} handleRefresh={setTime} />
    </>
  );
};

export default Comments;
