import React, { useCallback, useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import PhotosService from 'services/photos';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import getError from 'utils/getError';
import { yupResolver } from '@hookform/resolvers/yup';
import Activities from './Activities';
import PasswordContext from './Context';

const Input = styled.input`
  background: none;
  border: none;
  outline: none;

  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 12px;
`;

const InputContainer = styled.div`
  border: 2px solid white;
  border-radius: 100px;
  display: flex;

  button {
    width: 30px;
    height: 30px;
    display: block;
    padding: 0;
    margin: 0;
    background: rgb(202, 49, 132);
    background: linear-gradient(313deg, rgba(202, 49, 132, 1) 0%, rgba(218, 54, 61, 1) 62%, rgba(153, 44, 130, 1) 100%);
    border-radius: 100px;
    border: none;
    margin: 4px;
    flex: none;
    color: white;
  }
`;

export const Error = styled.div`
  font-size: 10px;
  text-align: center;
  margin-top: 3px;
  color: #616161;
  s
`;

const ImageWrapper = styled.div`
  img {
    max-width: min(100%, 800px);
    border-radius: 10px;
  }
`;

function tryToDownloadFile(id, data = {}, setError, name, mimetype, handleClose, ref) {
  PhotosService.show(id, data)
    .then((resp) => {
      if (resp.ok) {
        return resp.blob();
      }

      throw new Error();
    })
    .then((blob) => {
      blob = blob.slice(0, blob.size, mimetype);

      const url = window.URL.createObjectURL(blob);

      const img = document.createElement('img');
      img.src = url;

      const myNode = ref.current;

      while (myNode.firstChild) {
        myNode.removeChild(myNode.lastChild);
      }

      myNode.appendChild(img);

      //window.URL.revokeObjectURL(url);

      handleClose();
    })
    .catch((e) => {
      console.log(e);

      setError('password', 'incorrect_code');
    });
}

const schema = yup.object().shape({
  password: yup.string().required().min(5),
});

const Download = ({ data }) => {
  const [open, setOpen] = useState(true);
  const divRef = useRef(null);

  const password = useContext(PasswordContext);

  const { register, handleSubmit, errors, setError, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSubmit = useCallback(
    (formData) => {
      tryToDownloadFile(data.id, formData, setError, data.name, data.mimetype, handleClose, divRef);
    },
    [data.id, data.name, data.mimetype, setError, handleClose]
  );

  return (
    <>
      {open && (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <Input name="password" placeholder="Code" ref={register} type="password" />

            <button type="submit">
              <span className="icon-download2" />
            </button>
            {password.length > 0 && (
              <button
                onClick={() => {
                  setValue('password', password);
                }}
              >
                <span className="icon-eye" />
              </button>
            )}
          </InputContainer>

          {errors.password && <Error>{getError(errors.password)}</Error>}
        </form>
      )}

      <ImageWrapper ref={divRef} />

      {!open && <Activities data={data} />}
    </>
  );
};

export default Download;
