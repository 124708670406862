import React, { useEffect, useState } from 'react';
import SeenService from 'services/seen';
import { useToasts } from 'react-toast-notifications';
import { Comment } from '../Comments';

const Seen = ({ data }) => {
  const [seen, setSeen] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    SeenService.list(data.id)
      .then((response) => {
        setSeen(response.data);
      })
      .catch((err) => {
        addToast('Error occurs during fetching.', { appearance: 'error' });
      });
  }, []);

  return (
    <>
      {seen.map((item) => (
        <Comment asSeen={true} data={item} key={item.id} />
      ))}
    </>
  );
};

export default Seen;
