import styled from 'styled-components';
import React from 'react';

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    border-radius: 25px;
    max-width: 100%;
    max-height: 400px;
  }
`;

export const ActionsContainer = styled(ContentContainer)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  button {
    margin: 0 10px;
  }
`;

export const CropWrapper = styled.div``;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Rotate = styled.span`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 100px;
  border: 4px solid white;
  opacity: 0.4;
`;

export const AreaContainer = styled.div`
  border: none;
  border-radius: 25px;
  padding: 5px 17px;
  position: relative;
  margin-top: 1rem;
  background: #1a1a1a;
`;

export const Left = styled.div``;
export const Area = styled.textarea`
  width: 100%;
  border: none;
  background: none;
  color: white;
  min-height: 60px;
  outline: none;
`;

export const Save = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 42px;
  height: 42px;
  background: rgb(202, 49, 132);
  background: linear-gradient(313deg, rgba(202, 49, 132, 1) 0%, rgba(218, 54, 61, 1) 62%, rgba(153, 44, 130, 1) 100%);
  border-radius: 100px;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 27px;
  line-height: 51px;
  color: white;
  overflow: hidden;
  display: block;
  padding: 0;
  margin: 0;

  span {
    margin-left: -2px;
  }
`;

export const SecretContainer = styled.div`
  display: flex;

  span {
    font-size: 0.75rem;

    color: #777777;
  }
`;

export const SecretInput = styled.input`
  border: none;
  background: none;
  outline: none;
  color: white;
`;
