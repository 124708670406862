import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducers';

function configureStore(preloadedState = undefined) {
  const composedEnhancers = composeWithDevTools();

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}

export default configureStore();
