export const SET_USER = 'account/SET_USER';
export const SET_LOGGED_BY_FORM = 'account/SET_LOGGED_BY_FORM';
export const SET_TOKEN_AS_CHECKED = 'account/SET_TOKEN_AS_CHECKED';
export const LOGOUT = '@@LOGOUT';

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setLoggedByForm = () => ({
  type: SET_LOGGED_BY_FORM,
});

export const setTokenAsChecked = () => ({
  type: SET_TOKEN_AS_CHECKED,
});

export const logout = () => ({
  type: LOGOUT,
});
