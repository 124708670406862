import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import serviceContainer from 'services';
import { Container, Avatar, Button, Bottom } from './Menu.styled';

const MainMenu = () => {
  const isLogin = useSelector((state) => !!state.account.user);
  const name = useSelector((state) => (state.account.user ? state.account.user.name.slice(0, 1) : '?'));

  const history = useHistory();
  const { pathname } = useLocation();

  if (!isLogin) {
    return null;
  }

  return (
    <Container>
      <Avatar>{name}</Avatar>

      <Button
        selected={pathname === '/add'}
        onClick={() => {
          history.push('/add');
        }}
      >
        <span className="icon-upload2" />
      </Button>
      <Button
        selected={pathname === '/photos'}
        onClick={() => {
          history.push('/photos');
        }}
      >
        <span className="icon-images" />
      </Button>

      <Bottom>
        <Button
          onClick={() => {
            serviceContainer.logout();

            history.push('/');
          }}
        >
          <span className="icon-switch" />
        </Button>
      </Bottom>
    </Container>
  );
};

export default MainMenu;
