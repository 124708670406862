import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React from 'react';

export const StyledPaper = styled.div`
  margin-right: 60px;
  padding: 5px;

  h2 {
    font-size: 1rem;
    padding: 0;
    margin: 1rem 0;
    padding-left: 10px;
  }
`;

export const StyledFile = styled.div`
  height: 100%;
  border: 2px dashed #d3345c;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 25px;

  input {
    display: none;
  }

  label {
    display: block;
    padding: 40px 0;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export const SuccessContainer = styled.div``;

export const SuccessTick = styled.div`
  width: 120px;
  height: 120px;
  border: 4px solid #d93642;
  border-radius: 200px;
  text-align: center;
  font-size: 80px;
  line-height: 140px;
  color: #d93642;
  margin: auto;
`;

export const SuccessInfo = styled.div`
  text-align: center;
  margin: 2rem 0;
`;

export const SuccessButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const SuccessButton = styled(Link)`
  width: 100px;
  background: #585858;
  margin: 0 4px;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
`;
